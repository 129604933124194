import {
  GRAPPLE_ASSISTANT_SCOPE,
  GrappleChat,
  GrappleClientProvider,
} from "@monaco-digital/grapple-assistant";
import "@monaco-digital/grapple-assistant/style.css";
import { getToken, setToken, setDealAndToken } from "../shared/Helpers";
import config from "../config";
import { useLocation, useNavigate } from "react-router-dom";
import getAccessToken from "../app/services/getAccessToken";
import { useEffect, useState } from "react";

const Chatbot = ({ scope = GRAPPLE_ASSISTANT_SCOPE.All }) => {
  const [localToken, setLocalToken] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const dealAndToken = queryParams.get("customer");

  useEffect(() => {
    const handleGetToken = async (dealAndToken) => {
      const { data } = dealAndToken ? await getAccessToken(dealAndToken) : {};
      const savedToken = getToken();
      const finalToken = data?.token || savedToken;
      if (!finalToken) {
        navigate("/login");
      }
  
      setToken(finalToken);
      if(dealAndToken) {
        setDealAndToken(dealAndToken);
      }
      setLocalToken(finalToken);
    };
    handleGetToken(dealAndToken);
  }, [navigate, dealAndToken]);

  return (
    <div className="chatbot">
      {localToken && (
        <GrappleClientProvider
          scope={scope}
          api={{
            baseURL: config.GRAPPLE_ASSISTANT_BASE_URL,
            clientId: config.GRAPPLE_ASSISTANT_CLIENT_ID,
          }}
          authToken={localToken}
        >
          <GrappleChat />
        </GrappleClientProvider>
      )}
    </div>
  );
};

export default Chatbot;
