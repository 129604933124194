import Axios from "../api/axios";
import config from "../../config";
import { getToken } from "../../shared/Helpers";

// requestParams: { method, path, body, headers, token }
const rotaGateway = async (requestParams) => {
  if (!requestParams.token) {
    requestParams.token = getToken();
  }
  const resp = await Axios.post(`${config.BASE_URL}rota-gateway`, requestParams);
  if (resp.data?.success) { // This indicates a successful request from the grapple backend (gateway) to Rota
    resp.data = resp.data.data; // Unwrap actual Rota response
  } else {
    throw new Error(`Failed request to rota-gateway. Rota response: ${resp.data?.status} ${resp.data?.statusText} ${resp.data?.data}`);
  }
  return resp;
};

export default rotaGateway;
