import Axios from "../api/axios";
import config from "../../config";

const checkMerit = (message, email) => {
  return Axios.post(`${config.BASE_URL}check-merit`, {
    message: message,
    email: email,
  });
};

export default checkMerit;
