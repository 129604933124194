import React, { useState, useEffect, useRef } from "react";
import showdown from "showdown";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import DataInputsFlow from "./survey/DataInputs3.json";
import { useLocation, useNavigate } from "react-router-dom";
import "survey-core/modern.css";
import {
  getToken,
  removeToken,
  getDealAndToken,
  createImageElement,
  filterArrayByIssueType,
  getCurrentDateForPrompt } from "../shared/Helpers";
import getIssues from "../app/services/getIssues";
import Info from "../assets/img/Info.svg";
import Close from "../assets/img/Close.svg";
import Mic from "../assets/img/mic.png";
import Microphone from "../assets/img/microphone.png";
import getStatements from "../app/services/getStatements";
import grappleAi from "../app/services/grappleAi";
import getParagraphs from "../app/services/getParagraphs";
import getPrompts from "../app/services/getPrompts";
import getUser from "../app/services/getUser";
import updateUser from "../app/services/updateUser";
import Loader from "../components/Loader";
import checkMerit from "../app/services/checkMerit";
import rotaGateway from "../app/services/rotaGateway";
import Cycle from "../assets/img/cycle.svg";
import ErrorMessage from "../components/ErrorMessage";

const employmentIssues = [
  { value: "B", label: "Bullying" },
  { value: "RR", label: "Redundancy" },
  { value: "W", label: "Whistleblowing" },
  { value: "S", label: "Sickness" },
  { value: "Sn", label: "Suspension" },
  { value: "Dy", label: "Disciplinary" },
  { value: "H", label: "Unsafe workplace" },
  { value: "P", label: "Performance" },
  { value: "TWE", label: "Toxic work environment" },
  { value: "EW", label: "Excessive workload" },
  { value: "M", label: "Money owed" },
  { value: "F", label: "No contract" },
  { value: "OBT", label: "Other" },
];

const discriminationIssues = [
  {
    value: "DR",
    label: "Race or ethnicity",
  },
  {
    value: "DS",
    label: "Sex/gender",
  },
  {
    value: "DP",
    label: "Pregnancy",
  },
  {
    value: "DM",
    label: "Maternity",
  },
  {
    value: "DD",
    label: "Physical or Mental health condition",
  },
  {
    value: "DA",
    label: "Age",
  },
  {
    value: "DRn",
    label: "Religious belief",
  },
  {
    value: "DPl",
    label: "Philosophical belief",
  },
  {
    value: "DSy",
    label: "Sexual orientation",
  },
  {
    value: "DG",
    label: "Gender reassignment",
  },
  {
    value: "DMe",
    label: "Marital or civil partnership status",
  },
  {
    value: "ORT",
    label: "Other",
  },
];

const statusList = [
  { value: "E", label: "Still employed" },
  { value: "Rd", label: "Resigned" },
  { value: "T", label: "Dismissed" },
];

function MeritAssessment() {
  const converter = new showdown.Converter();
  const location = useLocation();
  const [queryParamValue, setQueryParamValue] = useState("");
  const [formData, setFormData] = useState("");
  const [issues, setIssues] = useState({
    other: [],
    employment: [],
    dismissal: [],
    redundancy: [],
  });
  const [modalContent, setModalContent] = useState("");
  const [statementList, setStatementList] = useState([]);
  const [paraPrompt, setParaPrompt] = useState("");
  const [timelineToolPrompt, setTimelineToolPrompt] = useState("");
  const [meritAssessmentPrompt, setMeritAssessmentPrompt] = useState("");
  const [isUserLoggedin, setIsUserLoggedin] = useState(false);
  const [userData, setUserData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onTextMarkdown = (_survey, options) => {
    let str = converter.makeHtml(options.text);
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    options.html = str;
  };
  const [dynamicQuestions, setDynamicQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const authToken = getToken();
  const dealAndToken = getDealAndToken();
  const [survey] = useState(() => new Model(DataInputsFlow));
  const [submissionError, setSubmissionError] = useState(null);
  const hasRunCompletionLogic = useRef(false);

  const handleError = (error, type, description) => {
    console.error(description, error);
    setSubmissionError({ error, type, description });
  };

  useEffect(() => {
    if (dynamicQuestions && survey) {
      const additionalQuestionsPage = survey.pages.find(
        (page) => page.name === "additional_questions"
      );

      if (additionalQuestionsPage) {
        // Remove existing dynamic questions and the optional question before adding new ones
        const dynamicQuestionsNames = dynamicQuestions.map((q) => q.name);
        additionalQuestionsPage.questions
          .filter((q) => dynamicQuestionsNames.includes(q.name))
          .forEach((q) => additionalQuestionsPage.removeElement(q));

        // Check if the optional question exists, remove it before re-adding it
        const extraQuestion =
          additionalQuestionsPage.getQuestionByName("extra_question");
        if (extraQuestion) {
          additionalQuestionsPage.removeElement(extraQuestion);
        }

        // Add new dynamic questions
        dynamicQuestions.forEach((question) => {
          additionalQuestionsPage.addNewQuestion("comment", question.name);
          const newQuestion = additionalQuestionsPage.getQuestionByName(
            question.name
          );
          newQuestion.title = question.title;
          newQuestion.isRequired = true;
        });

        // Add the extra optional question last
        if (!additionalQuestionsPage.getQuestionByName("extra_question")) {
          const extraQuestion = additionalQuestionsPage.addNewQuestion(
            "comment",
            "extra_question"
          );
          extraQuestion.title =
            "If there is anything else you need us to know, please write it here:";
          extraQuestion.isRequired = false; // Optional
        }
      }
    }
  }, [dynamicQuestions, survey]);

  const titleDivider = "||";
  const navigate = useNavigate();
  survey.completeText = "Continue";
  survey.setValue("selected_letter", "wp-letter");
  const recognitionRef = useRef(null);
  const accumulatedTextRef = useRef(""); // Accumulated recognized text
  const [descriptionText, setDescriptionText] = useState(""); // Text displayed in the textarea
  survey.setValue("time_limit", "");
  const generatedQuestions = survey.getValue("generatedQuestions");
  let generatedQuestionsArray = [];
  try {
    generatedQuestionsArray = generatedQuestions
      ? JSON.parse(generatedQuestions)
      : [];
  } catch (error) {
    generatedQuestionsArray = [];
    survey.setValue("generatedQuestions", "");
    console.error(
      "Invalid JSON format for generatedQuestions, setting it to empty:",
      error
    );
  }

  useEffect(() => {
    const fetchLoggedInUser = async (token) => {
      setQueryParamValue("wp-letter");
      try {
        const response = await getUser(token);
        const data = response.data;
        setUserData(data);
        survey.data = data.survey;
        if (!survey.fullname) {
          survey.setValue("fullname", data.name);
        }
        const initialValue = data.survey.case_summary || ""; // Fetch initial value
        setDescriptionText(initialValue); // Set the initial value to descriptionText
        accumulatedTextRef.current = initialValue; // Ensure ref starts with the initial value
        const queryParams = new URLSearchParams(location.search);
        const formType = queryParams.get("formType");
        if (queryParams) {
          const lastPage = queryParams.get("lastPage");
          if (lastPage) {
            survey.currentPage = lastPage;
            survey.setValue("form_type", "cdf3");
            document.body.className = `${lastPage} ${"cdf3"}`.trim();
          }
        }
        if (formType === "timeline") {
          survey.setValue("form_type", "timeline");
          setFormData("timeline");
        } else if (formType === "timelimits") {
          survey.setValue("form_type", "timelimits");
          setFormData("questions");
          survey.currentPage = "questions-1";
        } else if (formType === "questions") {
          survey.setValue("form_type", "questions");
          survey.currentPage = "additional-info";
          setFormData("questions");
        } else if (data.survey.hasOwnProperty("statements_list")) {
          survey.setValue("form_type", "cdf3");
          setFormData("cdf3");
          survey.currentPage = "additional-info";
        } else {
          survey.setValue("form_type", "cdf1");
          setFormData("cdf1");
          survey.currentPage = "additional-info";
        }
        setIsUserLoggedin(true);
      } catch (error) {
        removeToken();
        setIsUserLoggedin(false);
        console.error(error);
      }
    };

    if (!authToken || !dealAndToken) {
      removeToken();
      navigate("/login");
    }
    fetchLoggedInUser(authToken);
  }, [navigate, authToken, dealAndToken, location.search, survey]);

  useEffect(() => {
    const fetchIssues = async () => {
      try {
        const response = await getIssues();
        const data = response.data.data;
        const issueTypes = [1, 2, 3, 4];
        const newIssues = issueTypes.reduce((acc, type) => {
          acc[type] = filterArrayByIssueType(data, type).map((issue) => ({
            value: issue.attributes.TopicId,
            text: issue.attributes.Name,
          }));
          return acc;
        }, {});

        const {
          data: {
            data: {
              attributes: {
                ParaPrompt,
                TimelineToolPrompt,
                MeritAssessmentPrompt,
              },
            },
          },
        } = await getPrompts();
        setParaPrompt(ParaPrompt);
        setTimelineToolPrompt(TimelineToolPrompt);
        setMeritAssessmentPrompt(MeritAssessmentPrompt);

        setIssues({
          employment: newIssues[1],
          other: [{ value: "none", text: "None" }, ...newIssues[2]],
          dismissal: newIssues[3],
          redundancy: newIssues[4],
        });
      } catch (error) {
        handleError(error, 'load', "Error fetching issues");
      }
    };

    fetchIssues();
  }, []);

  survey.getQuestionByName("other_issues").choices = [
    issues.other[0], // Keep the first item
    ...issues.other.slice(2), // Skip the second item and take the rest
  ];

  useEffect(() => {
    const handleClickOutsideTooltip = (e) => {
      const tooltip = document.getElementById("tooltip");
      if (!tooltip) {
        return;
      }
      if (tooltip.style.display === "block" && !tooltip.contains(e.target)) {
        tooltip.style.display = "none";
      }
    };
    document.addEventListener("click", handleClickOutsideTooltip);
    return () => {
      document.removeEventListener("click", handleClickOutsideTooltip);
    };
  }, []);

  const handleStartRecognition = () => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (SpeechRecognition) {
      if (!recognitionRef.current) {
        recognitionRef.current = new SpeechRecognition();
        recognitionRef.current.lang = "en-GB";
        recognitionRef.current.continuous = true;
        recognitionRef.current.interimResults = true;

        recognitionRef.current.onstart = () => {
          document.querySelector(".start-microphone-icon").src = Microphone; // Switch to active mic image
          document.querySelector(".start-microphone-button").style.display =
            "none"; // Hide start button
          document.querySelector(".stop-microphone-button").style.display =
            "inline"; // Show stop button
          accumulatedTextRef.current += " "; // Add a space before appending new text
        };

        recognitionRef.current.onend = () => {
          document.querySelector(".start-microphone-icon").src = Mic; // Switch to inactive mic image
          recognitionRef.current = null; // Clear the recognition reference
        };

        recognitionRef.current.onresult = (event) => {
          let interimTranscript = "";

          for (let i = event.resultIndex; i < event.results.length; i++) {
            const transcript = event.results[i][0].transcript;
            if (event.results[i].isFinal) {
              accumulatedTextRef.current += transcript;
            } else {
              interimTranscript += transcript;
            }
          }

          const updatedText = accumulatedTextRef.current + interimTranscript;
          setDescriptionText(updatedText);
          survey.setValue("case_summary", updatedText);
        };

        recognitionRef.current.start(); // Start recognition
      }
    } else {
      alert("Speech recognition is not supported by your browser.");
    }
  };

  const handleStopRecognition = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop(); // Stop recognition
      recognitionRef.current = null; // Clear the recognition reference to allow reinitialization
      document.querySelector(".start-microphone-button").style.display =
        "inline"; // Show start button
      document.querySelector(".stop-microphone-button").style.display = "none"; // Hide stop button
      document.querySelector(".start-microphone-icon").src = Mic; // Switch to inactive mic image
    }
  };

  const onAfterRenderQuestion = (__survey, options) => {
    if (options.question.name === "case_summary") {
      const container = options.htmlElement;

      // Add start button if it doesn't exist
      if (!container.querySelector(".start-microphone-button")) {
        const startButton = document.createElement("button");
        startButton.type = "button";
        startButton.className = "microphone start-microphone-button";
        startButton.innerHTML = `<img class="start-microphone-icon" src=${Mic} width="24" height="24" alt="microphone" /> Start`;
        startButton.style.display = "inline"; // Start button is visible initially
        startButton.addEventListener("click", handleStartRecognition);
        container.appendChild(startButton);
      }

      // Add stop button if it doesn't exist
      if (!container.querySelector(".stop-microphone-button")) {
        const stopButton = document.createElement("button");
        stopButton.type = "button";
        stopButton.className = "microphone stop-microphone-button";
        stopButton.innerHTML = `<img class="stop-microphone-icon" src=${Microphone} width="24" height="24" alt="microphone" /> Stop`;
        stopButton.style.display = "none"; // Stop button is hidden initially
        stopButton.addEventListener("click", handleStopRecognition);
        container.appendChild(stopButton);
      }

      const textArea = container.querySelector("textarea");
      if (textArea) {
        textArea.value = descriptionText;
        textArea.addEventListener("input", (e) => {
          accumulatedTextRef.current = e.target.value;
          setDescriptionText(e.target.value);
          survey.setValue("case_summary", e.target.value);
        });
      }
    }
    const employmentIssue = survey.getQuestionByName("employment_issues");
    const employmentStatus = survey.getValue("employment_status");
    if (employmentIssue && employmentStatus === "T") {
      survey.getQuestionByName("employment_issues").choices = issues.employment;
    } else {
      survey.getQuestionByName("employment_issues").choices = issues.employment;
    }

    options.htmlElement.querySelectorAll(".sv-string-viewer").forEach((el) => {
      const text = el.innerText;
      if (text.includes(titleDivider)) {
        const [textBeforeDivider, titleText] = text?.split(titleDivider);
        el.title = titleText;
        el.innerText = textBeforeDivider;

        const img = createImageElement(Info, "Tooltip icon", (e) => {
          e.stopPropagation();
          setModalContent(titleText);
          const rect = img.getBoundingClientRect();
          const tooltip = document.getElementById("tooltip");
          tooltip.style.display = "block";
          tooltip.style.left = `${rect.right + 10}px`;
          tooltip.style.top = `${rect.top}px`;
        });

        el.insertAdjacentElement("afterend", img);
      }
    });
  };

  const handleCustomButtonClick = () => {
    navigate(-1);
  };

  const onValueChanged = async (__survey, options) => {
    if (__survey.currentPage.name === "additional_questions") {
      const userInput = __survey.data;

      if (isUserLoggedin && authToken) {
        try {
          await updateUser(authToken, { survey: userInput });
        } catch (e) {
          console.error("Error saving survey data:", e);
        }
      }
    }
  };

  const onAfterRenderPage = (__survey, options) => {
    let pageName = survey.currentPage.name;
    let param = formData || "";
    document.body.className = `${pageName} ${param}`.trim();

    if (
      survey.currentPage.name === "time-limit-calculator" ||
      survey.currentPage.name === "respond-to-employer"
    ) {
      const header = document.querySelector(
        `.time-limit-calculator .sv-action-bar.sv-footer.sv-body__footer, .respond-to-employer .sv-action-bar.sv-footer.sv-body__footer`
      );

      if (header && !header.querySelector(".back-btn")) {
        const btn = document.createElement("button");
        btn.type = "button";
        btn.id = "back-btn";
        btn.className = "btn back-btn none";
        btn.innerHTML = "<span>Back</span>";

        btn.onclick = function () {
          handleCustomButtonClick();
        };

        header.appendChild(btn);
      }
    }
  };

  const onCurrentPageChanged = async (sender, options) => {
    const userInput = sender.data;
    const nextButton = document.querySelector('input[value="Continue"]');
    const howLong = userInput?.how_long || "";
    const dismissalIssues = [];
    const redundancyIssues = [];
    const employmentIssues = userInput.employment_issues || [];
    const otherIssues =
      userInput.other_issues && userInput.other_issues.length > 0
        ? [...userInput.other_issues, "D"]
        : [...(userInput.other_issues || [])];
    const employmentStatus = userInput?.employment_status || "";
    const combinedIssues = [
      ...dismissalIssues,
      ...redundancyIssues,
      ...employmentIssues,
      ...otherIssues,
    ];
    const combinedTopics = [employmentStatus, howLong, ...combinedIssues];
    if (isUserLoggedin && authToken) {
      try {
        await updateUser(authToken, { survey: userInput });
      } catch (e) {
        console.error(e);
      }
    }
    if (
      survey.currentPage &&
      survey.currentPage.name === "additional_questions" &&
      generatedQuestionsArray.length < 1
    ) {
      try {
        setIsLoading(true);
        const { data: userData } = await getUser(authToken);
        const caseSummary = userData.survey.case_summary;
        let employmentIssuesSelected = "";
        if (
          userData.survey.employment_issues &&
          userData.survey.employment_issues.length > 0
        ) {
          employmentIssuesSelected = employmentIssues
            .filter((employmentIssue) =>
              userData.survey.employment_issues.includes(employmentIssue.value)
            )
            .map((item) => item?.label)
            .join(", ");
        }
        let discriminationIssuesSelected = "";
        if (
          userData.survey.other_issues &&
          userData.survey.other_issues.length > 0
        ) {
          discriminationIssuesSelected = discriminationIssues
            .filter((discriminationIssue) =>
              userData.survey.other_issues.includes(discriminationIssue.value)
            )
            .map((item) => item?.label)
            .join(", ");
        }
        let employmentStatus = statusList.find(
          (status) => status.value === userData?.survey?.employment_status
        );
        employmentStatus = statusList?.label;
        const jobDuration = userData.survey.years_employed;
        const filterQuery =
          userData.survey.statements_list.length > 0
            ? userData.survey.statements_list
                .map((id) => `&filters[id][$in]=${encodeURIComponent(id)}`)
                .join("")
            : "&filters[id][$in]=";
        const { data: { data: paragraphsData } = {} } = await getParagraphs(
          filterQuery
        );
        const paragraphsText = paragraphsData
          .map((item) => item?.attributes?.Paragraph)
          .filter(Boolean)
          .join("\n\n");
        const message =
          "Always output me the list in an array with name and title. Name can be q1, q2, etc. Do not send anything extra, the format needs to be an array only";
        const systems = timelineToolPrompt;
        const system = `${systems}\n\n[1] Summary: ${caseSummary} [2] Paragraphs: ${paragraphsText} [3] Case issues, including: 
          ### Are you still employed: ${employmentStatus}. 
          ### How long have you been in your job: ${jobDuration}. 
          ### Employment issues: ${
            employmentIssuesSelected ? employmentIssuesSelected : "None"
          }. 
          ### Discrimination issues: ${
            discriminationIssuesSelected ? discriminationIssuesSelected : "None"
          }.`;
        const { data: { response } = {} } = await grappleAi(
          message,
          system,
          userData.email,
          'timeline-tool-questions'
        );
        let generatedQuestions = [];
        try {
          generatedQuestions = JSON.parse(response);
        } catch (error) {
          throw new Error("Invalid JSON in response (timelineToolPrompt). Resp: " + response);
        }
        survey.setValue("generatedQuestions", response);
        setDynamicQuestions(generatedQuestions); // Assuming the API returns an array of questions
        setIsLoading(false);
      } catch (error) {
        handleError(error, 'load', "Error fetching questions");
      }
    }
    if (
      survey.currentPage &&
      survey.currentPage.name === "additional_questions" &&
      generatedQuestionsArray.length > 0
    ) {
      setDynamicQuestions(generatedQuestionsArray);
    }
    if (
      survey.currentPage &&
      survey.currentPage.name === "questions-6" &&
      options.isNextPage
    ) {
      nextButton.disabled = true;
      try {
        const { data } = await getStatements(
          employmentStatus,
          combinedIssues,
          combinedTopics
        );
        const statementList = data.map((item) => ({
          id: item.id,
          statement: item.title,
        }));
        setStatementList(statementList);
        nextButton.disabled = false;
      } catch (e) {
        console.error(e);
      }
    }
    if (survey.currentPage.name === "statements" && options.isNextPage) {
      survey.setValue("statements_list", []);
      try {
        const {
          case_summary: summary,
          years_employed,
          employment_status,
        } = sender.data || {};
        let employmentStatus = statusList.find(
          (status) => status.value === employment_status
        );
        employmentStatus = employmentStatus?.label;
        let employmentIssuesSelected = "";
        const employmentIssuesList = [
          { value: "B", label: "Bullying" },
          { value: "RR", label: "Redundancy" },
          { value: "W", label: "Whistleblowing" },
          { value: "S", label: "Sickness" },
          { value: "Sn", label: "Suspension" },
          { value: "Dy", label: "Disciplinary" },
          { value: "H", label: "Unsafe workplace" },
          { value: "P", label: "Performance" },
          { value: "TWE", label: "Toxic work environment" },
          { value: "EW", label: "Excessive workload" },
          { value: "M", label: "Money owed" },
          { value: "F", label: "No contract" },
          { value: "OBT", label: "Other" },
        ];
        if (
          sender.data.employment_issues &&
          sender.data.employment_issues.length > 0
        ) {
          employmentIssuesSelected = employmentIssuesList
            .filter((employmentIssue) =>
              sender.data.employment_issues.includes(employmentIssue.value)
            )
            .map((item) => item?.label)
            .join(", ");
        }
        let discriminationIssuesSelected = "";
        if (sender.data.other_issues && sender.data.other_issues.length > 0) {
          discriminationIssuesSelected = discriminationIssues
            .filter((discriminationIssue) =>
              sender.data.other_issues.includes(discriminationIssue.value)
            )
            .map((item) => item?.label)
            .join(", ");
        }
        const message = `Statements: ${JSON.stringify(
          statementList
        )}\n\nSummary: ${summary}
        \n\n### Are you still employed: ${employmentStatus}
        \n\n### How long have you been in your job: ${years_employed}
        \n\n### Employment issues: ${
          employmentIssuesSelected ? employmentIssuesSelected : "None"
        }
        \n\n### Discrimination issues: ${
          discriminationIssuesSelected ? discriminationIssuesSelected : "None"
        }.`;
        let ids = [];
        if (queryParamValue !== "response-letter") {
          const { data: { response } = {} } = await grappleAi(
            message,
            paraPrompt,
            userData.email
          );
          try {
            ids = JSON.parse(response || "[]");
          } catch (error) {
            throw new Error("Invalid JSON in response (paraPrompt). Resp: " + response);
          }
        }
        const filterQuery =
          ids.length > 0
            ? ids
                .map((id) => `&filters[id][$in]=${encodeURIComponent(id)}`)
                .join("")
            : "&filters[id][$in]=";

        const { data: { data: paragraphsData } = {} } = await getParagraphs(
          filterQuery
        );
        const statementslist = paragraphsData.map((item) => ({
          value: item.id,
          text: item.attributes.Statement,
        }));
        if (statementslist.length === 0) {
          survey
            .getQuestionByName("statements_list")
            .addError(
              "We can't give you accurate advice based on the info provided. Please go back and write a little more detail. Thanks so much!"
            );
          return;
        }
        survey.getQuestionByName("statements_list").choices = statementslist;
      } catch (error) {
        handleError(error, 'load', "Error fetching paragraphs");
      }
    }
  };

  const blockUserInteraction = (event) => {
    if (isSubmitting) {
      event.preventDefault(); // Prevent default action
      event.stopPropagation(); // Stop event from propagating
      console.log("Blocked user interaction during submission.");
    }
  };

  const onCompleting = async (sender, options) => {
    if (!isUserLoggedin || !authToken) {
      handleError(
        new Error("An attempt to submit merit assessment for unauthenticated user"),
        'submit',
        "Error submitting merit assessment"
      );
      return;
    }

    // Check the form is already being submitted
    if (hasRunCompletionLogic.current) {
      return;
    }
    hasRunCompletionLogic.current = true;

    options.allowComplete = false;
    setIsSubmitting(true);
    document.addEventListener("keydown", blockUserInteraction, true);
    document.addEventListener("mousedown", blockUserInteraction, true);
    document.addEventListener("touchstart", blockUserInteraction, true);

    try {
      const {
        case_summary: summary,
        statements_list,
        age,
        gender,
        total_salary,
      } = sender.data || {};
      const backendData = sender.data;
      const filterQuery =
        statements_list.length > 0
          ? statements_list
              .map((id) => `&filters[id][$in]=${encodeURIComponent(id)}`)
              .join("")
          : "&filters[id][$in]=";
      const { data: { data: paragraphsData } = {} } = await getParagraphs(
        filterQuery
      );
      const paragraphsText = paragraphsData
        .map((item) => item?.attributes?.Paragraph)
        .filter(Boolean)
        .join("\n\n");
      let employmentIssuesSelected = "";
      if (
        backendData.employment_issues &&
        backendData.employment_issues.length > 0
      ) {
        employmentIssuesSelected = employmentIssues
          .filter((employmentIssue) =>
            backendData.employment_issues.includes(employmentIssue.value)
          )
          .map((item) => item?.label)
          .join(", ");
      }

      let discriminationIssuesSelected = "";
      if (backendData.other_issues && backendData.other_issues.length > 0) {
        discriminationIssuesSelected = discriminationIssues
          .filter((discriminationIssue) =>
            backendData.other_issues.includes(discriminationIssue.value)
          )
          .map((item) => item?.label)
          .join(", ");
      }
      let employmentStatus = statusList.find(
        (status) => status.value === backendData.employment_status
      );
      employmentStatus = employmentStatus.label;
      const jobDuration = backendData.years_employed;
      const questions = JSON.parse(backendData.generatedQuestions);
      const additionalQuestions = questions
        .map(
          (q) => `Questions: ${q.title}\n\nAnswer: ${backendData[q.name]}\n`
        )
        .join("\n");
      const manualQuestion = `Questions: If there is anything else you need us to know please write it here:\n\nAnswer: ${
        survey.data.extra_question ? backendData.extra_question : "-"
      }`;
      const relevant_date = backendData.date
        ? new Date(backendData.date)
        : backendData.date_last
        ? new Date(backendData.date_last)
        : null;
      const asac_start = backendData.date2
        ? backendData.date2
        : "not started yet";
      const asac_issues = backendData.date3
        ? backendData.date3
        : "client hasn't provided this info";

      const date1_1 = backendData.date
        ? new Date(backendData.date)
        : backendData.date_last
        ? new Date(backendData.date_last)
        : null;
      const date1_2 = backendData.date1_2
        ? new Date(backendData.date1_2)
        : null;
      const deadline1 =
        date1_1 && date1_2
          ? date1_1 < date1_2
            ? date1_1
            : date1_2
          : date1_1 || date1_2;

      let time_limit = null;
      if (deadline1) {
        const dates = addMonthsReliable(deadline1, 3);
        dates.setDate(dates.getDate() - 1);
        const mnth = `0${dates.getMonth() + 1}`.slice(-2);
        const day = `0${dates.getDate()}`.slice(-2);
        time_limit = `${dates.getFullYear()}-${mnth}-${day}`;
      } else {
        console.error("No valid date available");
      }

      function addMonthsReliable(date, months) {
        const d = new Date(date);
        const day = d.getDate();
        d.setMonth(d.getMonth() + months);
        if (d.getDate() < day) {
          d.setDate(0);
        }
        return d;
      }
      const date2 = backendData.date2 ? new Date(backendData.date2) : null;
      const date3 = backendData.date3 ? new Date(backendData.date3) : null;
      let tribunal_deadline =
        "unknown at the moment since the client hasn't provided ACAS certificate info. Treat it as if the deadline hasn't passed yet";
      // Check if all three dates are present before proceeding with the calculation
      if (deadline1 && date2 && date3) {
        // First option: deadline1 + 3 calendar months minus 1 day + (day3 - day2)
        const date1PlusThreeMonths = addMonthsReliable(deadline1, 3);
        date1PlusThreeMonths.setDate(date1PlusThreeMonths.getDate() - 1); // Subtract 1 day

        // Calculate the difference in days between date3 and date2
        const dayDifference = Math.ceil(
          (date3 - date2) / (1000 * 60 * 60 * 24)
        );

        // Add this difference to the first deadline
        const firstDeadline = new Date(date1PlusThreeMonths);
        firstDeadline.setDate(firstDeadline.getDate() + dayDifference);

        // Second option: date3 + 1 calendar month minus 1 day
        const secondDeadline = addMonthsReliable(date3, 1);
        secondDeadline.setDate(secondDeadline.getDate() - 1);

        // Pick the later date between firstDeadline and secondDeadline
        const finalDeadline2 =
          firstDeadline > secondDeadline ? firstDeadline : secondDeadline;

        // Format deadline2 as YYYY-MM-DD
        const mnth2 = `0${finalDeadline2.getMonth() + 1}`.slice(-2);
        const day2 = `0${finalDeadline2.getDate()}`.slice(-2);
        tribunal_deadline = [finalDeadline2.getFullYear(), mnth2, day2].join(
          "-"
        );
      }
      const allQuestions = `${additionalQuestions}\n${manualQuestion}`;
      if (!meritAssessmentPrompt) {
        throw new Error("Merit assessment prompt was not set");
      }
      let updatedSummary = `${meritAssessmentPrompt}\n\nSummary\n${summary}\n\nParagraphs: ${paragraphsText}\n\nCase issues, including:\n\nAre you still employed: ${employmentStatus}\n\nHow long have you been in your job: ${jobDuration} years\n\nAge: ${age}\n\nGender: ${gender}\n\nQuestions & Answers:\n${allQuestions}\n\nTime Limit Info:\n`;
      updatedSummary += `\nToday's date: ${getCurrentDateForPrompt()}`;
      if (relevant_date) {
        updatedSummary += `\nLast relevant date: ${relevant_date}`;
      }
      if (time_limit) {
        updatedSummary += `\nACAS early conciliation deadline: ${time_limit}`;
      }
      if (asac_start) {
        updatedSummary += `\nACAS start date: ${asac_start}`;
      }
      if (asac_issues) {
        updatedSummary += `\nACAS certificate issued: ${asac_issues}`;
      }
      if (tribunal_deadline) {
        updatedSummary += `\nTribunal deadline: ${tribunal_deadline}`;
      }
      if (employmentIssuesSelected) {
        updatedSummary += `\n\n### Employment issues: ${employmentIssuesSelected}`;
      }
      if (discriminationIssuesSelected) {
        updatedSummary += `\n\n### Discrimination issues: ${discriminationIssuesSelected}`;
      }
      if (total_salary) {
        updatedSummary += `\n\n### Annual income: ${total_salary}`;
      }
      const paragraph = updatedSummary;
      const { data: { response } = {} } = await checkMerit(paragraph, userData.email);  
      let parsedResponse;
      try {
        parsedResponse = JSON.parse(response);
      } catch (error) {
        throw new Error("Invalid JSON in response (checkMerit). Resp: " + response);
      }
      const statementsList = paragraphsText;
      const formData = {
        dealID: userData.dealID,
        paragraph: userData.survey.paragraph,
        date1: relevant_date,
        time_limit: time_limit,
        time_limit2: tribunal_deadline,
        employmentIssues: employmentIssuesSelected,
        otherIssues: discriminationIssuesSelected,
        statementsList: statementsList,
        additionalQuestions: allQuestions,
        score: parsedResponse.score,
        justification: parsedResponse.justification,
        ...backendData,
      };
      await updateUser(authToken, {
        isMeritAssessmentSubmitted: true,
        survey: backendData,
      });
      await rotaGateway({
        method: 'POST',
        path: 'v1/update-merit-result',
        body: formData,
      });
      options.allowComplete = true;
      navigate("/thank-you");
    } catch (e) {
      handleError(e, 'submit', "Error submitting merit assessment");
    }
  };

  return (
    <div className="user-flow case-details">
      {!submissionError && !isLoading && (
        <>
          <div id="tooltip" className="tooltip" style={{ display: "none" }}>
            <span
              className="close-icon"
              onClick={() =>
                (document.getElementById("tooltip").style.display = "none")
              }
            >
              <img src={Close} width="12" height="12" alt="close-icon" />
            </span>
            {modalContent}
          </div>
          <div className="input-header"></div>
          <Survey
            model={survey}
            onTextMarkdown={onTextMarkdown}
            onAfterRenderQuestion={onAfterRenderQuestion}
            onAfterRenderPage={onAfterRenderPage}
            onCurrentPageChanged={onCurrentPageChanged}
            onCompleting={onCompleting}
            onValueChanged={onValueChanged}
          />
        </>
      )}
      {!submissionError && isLoading && <Loader />}
      {!submissionError && isSubmitting && (
        <div className="submit-loader">
          <div>
            <img src={Cycle} width="auto" height="auto" alt="cycle" />
            <p>Please bear with, this could take a couple of minutes</p>
          </div>
        </div>
      )}
      <ErrorMessage {...submissionError} componentName="MeritAssessment" />
    </div>
  );
}
export default MeritAssessment;
