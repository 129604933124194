import Axios from "../api/axios";
import config from "../../config";

const getAccessToken = (dealAndToken) => {
  return Axios.post(`${config.BASE_URL}generate-token`, {
    dealAndToken,
    email: _legacy_maybeGetEmail(dealAndToken),
  });
};

export default getAccessToken;

const _legacy_maybeGetEmail = (maybeEmail) => {
  if (maybeEmail.includes('@') && maybeEmail.includes('.')) {
    return maybeEmail;
  }
  const decEmail = _legacy_decryptEmail(maybeEmail);
  if (decEmail.includes('@') && decEmail.includes('.')) {
    return decEmail;
  }
  return undefined;
};

const _legacy_decryptEmail = (encryptedEmail) => {
  let result = "";
  for (let i = 0; i < encryptedEmail.length; i++) {
    let charCode = encryptedEmail.charCodeAt(i);
    let decryptedCharCode = charCode - 3;
    result += String.fromCharCode(decryptedCharCode);
  }
  return result;
};