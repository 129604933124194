import React, { useEffect, useState } from "react";
import getAccessToken from "../app/services/getAccessToken";
import { removeToken, setToken, setDealAndToken } from "../shared/Helpers";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import getUser from "../app/services/getUser";

function CustomerAccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dealAndToken = queryParams.get("customer");
  const paramType = queryParams.get("type");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const decryptAndFetchToken = async () => {
      if (dealAndToken) {
        try {
          const { data } = await getAccessToken(dealAndToken);
          const userDetails = await getUser(data.token);
          const userSurveyData = userDetails.data.survey;
          setToken(data.token);
          setDealAndToken(dealAndToken);
          if (paramType === "assessment") {
            navigate("/merit-assessment");
          } else if (
            !userSurveyData.company_name &&
            userSurveyData.total_salary
          ) {
            navigate("/case-details?formType=contact");
          } else if (
            userSurveyData.total_salary &&
            userSurveyData.offer_received
          ) {
            navigate("/preview-letter");
          } else {
            navigate("/case-details");
          }
        } catch (e) {
          setErrorMessage("User not found");
        }
      } else {
        removeToken();
        navigate("/login");
      }
    };

    decryptAndFetchToken();
  }, [navigate, paramType, dealAndToken]);

  return (
    <div className="login auth">
      <Loader />
      {errorMessage && <p className="error response">{errorMessage}</p>}
    </div>
  );
}

export default CustomerAccess;
