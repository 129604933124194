import React, { useEffect, useState } from "react";
import getAccessToken from "../app/services/getAccessToken";
import { removeToken, setToken, setLoginType, setDealAndToken } from "../shared/Helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import LoginImage from "../assets/img/login-banner.svg";
import eye from "../assets/img/eye.svg";
import * as Yup from "yup";

const loginValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

function LawyerAccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dealAndToken = queryParams.get("customer");
  const initialValues = {
    email: "",
    password: "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginSubmit = async (values) => {
    const hardcodedPassword = "monacosystems201!";

    if (values.password === hardcodedPassword) {
      try {
        const { data } = await getAccessToken(dealAndToken);
        setToken(data.token);
        setDealAndToken(dealAndToken);
        setLoginType("lawyer");
        navigate("/dashboard");
      } catch (e) {
        setErrorMessage("User not found");
      }
    } else {
      setErrorMessage("Incorrect password");
    }
  };

  useEffect(() => {
    if (!dealAndToken) {
      navigate("/login");
    }
    removeToken();
  }, [navigate, dealAndToken]);

  return (
    <div className="login auth">
      <div className="auth-form">
        <div className="form-inner">
          <h2>Lawyer Access</h2>
          <h5>Please log in to proceed</h5>
          <Formik
            initialValues={initialValues}
            validationSchema={loginValidationSchema}
            onSubmit={handleLoginSubmit}
          >
            {({ isValid }) => (
              <Form>
                <div>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="textbox"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <div>
                  <div className="password-field">
                    <Field
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder="Password"
                      className="textbox"
                    />
                    <img
                      src={eye}
                      alt="toggle visibility"
                      width="20px"
                      onClick={handleTogglePassword}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />
                </div>
                {errorMessage && (
                  <p className="error response">{errorMessage}</p>
                )}
                <button type="submit">Login</button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div
        className="auth-image"
        style={{ backgroundImage: `url(${LoginImage})` }}
      ></div>
    </div>
  );
}

export default LawyerAccess;
