const config = {
  domain: "https://api.stage.monacosolicitors.grapple.uk",
  BASE_URL: "https://api.stage.monacosolicitors.grapple.uk/api/",
  token:
    "f607906e2f353692fe7f5d47c5414fe0c70142b81b0af3be4b1d10de13226ef2f917fcb122084a0e85304ee2e4573b055a2bd5886df8be4eb8e92fe270cc97dadd96b4407ef352f87f6ae0b69366a5cdcfb975444605e20c160657d4a6e4ca7165eaa9c4dddff9030671d0534cf4d16b0bba0ba19763224082210970776e93cd",
  baseName: "/",
  language: "en",
  stripe_key:
    "pk_test_51O9uqwJuV8CPgQ4yIQmh3zHpOMiSdk7cRTfSFF8SqYKLOH94YkIbFTqNWicPPyOa6ubXmJkrhYZrpge9QY5DzYV200bfOnWFD5",
  GRAPPLE_ASSISTANT_BASE_URL: "https://squid-app-pc5xv.ondigitalocean.app/api",
  GRAPPLE_ASSISTANT_CLIENT_ID: "65541C78CE834F6775B7578A8CE93B2F",
  SENTRY_DSN: "https://84ed5895b5bb556e43cdac09c24f4686@o4508801186660352.ingest.de.sentry.io/4508805487460432",
  ENV: "stage",
};

export default config;
