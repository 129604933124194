import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CheckIcon from "../assets/img/Big-Check.svg";
import ReactMarkdown from "react-markdown";
import EmailIcon from "../assets/img/Email.svg";
import ThankYouModal from "../components/ThankYouModal";
import YoutubeEmbed from "../components/YoutubeEmbed";
import { fetchAICompletion } from "../shared/UseGrappleStream";
import updateUser from "../app/services/updateUser";
import { getToken, getDealId, getLoginType } from "./../shared/Helpers";
import sendMail from "../app/services/sendMail";
import EmailModal from "../components/EmailModal";

function CheckLetter() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [finalLetter, setFinalLetter] = useState("");
  const [error, setError] = useState("");
  const [isStreamComplete, setIsStreamComplete] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { letterName, letterTitle, letterInfo, mainContent, userData } =
    state || {};
  const localDealId = getDealId();
  const authToken = getToken();
  const loginType = getLoginType();

  useEffect(() => {
    if (state) return;
    navigate("");
  }, [navigate, state]);

  useEffect(() => {
    const updateSurveyValue = (newChunk) => {
      setFinalLetter((prevValue) => {
        const headerText =
          letterName !== "response-letter" &&
          prevValue === "" &&
          letterInfo.HeaderText
            ? letterInfo.HeaderText
            : "";
        return headerText + prevValue + newChunk;
      });
    };

    const handleCompletion = () => {
      setFinalLetter((prevValue) => {
        const footerText = letterInfo.FooterText ? letterInfo.FooterText : "";

        return prevValue + footerText;
      });
      setIsStreamComplete(true);
    };

    const processfinalLetter = async () => {
      const loginType = null;
      const letterType = "check";
      let initialMessage = "";

      initialMessage = mainContent;

      try {
        await fetchAICompletion(
          initialMessage,
          letterName,
          loginType,
          letterType,
          userData?.email,
          updateSurveyValue,
          handleCompletion
        );
      } catch (e) {
        console.error(e);
        setError("An error occurred while processing the letter.");
      }
    };

    processfinalLetter();
  }, [
    letterInfo.CheckYourLetterPrompt,
    letterInfo.FooterText,
    letterInfo.HeaderText,
    letterName,
    mainContent,
    userData?.email,
  ]);

  const handleEmailModal = () => {
    setShowEmailModal(!showEmailModal);
  };

  const youtubeEmbedIds = {
    "advice-note": "Eyzj9Ofh0GI",
    "grievance-letter": "E46KLxeh9h4",
    "wp-letter": "4hF_OsyD0Ds",
    "et1-letter": "q1G3tVsCuf8",
    "dsar-letter": "KmKXKMJvMhc",
    "response-letter": "Eyzj9Ofh0GI",
    "witness-statement-letter": "q1G3tVsCuf8",
  };

  const embedId = youtubeEmbedIds[letterName] || "Eyzj9Ofh0GI";

  const sendToMail = async () => {
    let letterText = "";
    let templateId = "";
    let fileName = "";
    const paidConUrl = localDealId
      ? `https://www.monacosolicitors.co.uk/book-your-consultation?id=${localDealId}`
      : "https://www.monacosolicitors.co.uk/book-your-paid-consultation";
    if (letterName === "grievance-letter") {
      letterText = finalLetter;
      templateId = "d-222a8db1fd4e4c7b97577ab8f4a4633d";
      fileName = "monaco_ai_gr_letter";
    } else if (letterName === "wp-letter") {
      letterText = finalLetter;
      templateId = "d-5e8d1925c2bb4c969469369aa79ee7d0";
      fileName = "monaco_ai_wp_letter";
    } else if (letterName === "et1-letter") {
      letterText = finalLetter;
      templateId = "d-5820f0f1298f4ac3ade17f39897ef7db";
      fileName = "monaco_ai_et1";
    } else if (letterName === "dsar-letter") {
      letterText = finalLetter;
      templateId = "d-99f15745ec174eea8bc33647bf251f40";
      fileName = "monaco_ai_dsar";
    } else if (letterName === "response-letter") {
      letterText = finalLetter;
      templateId = "d-e666093303644c8fa06882db37396f94";
      fileName = "monaco_ai_response";
    } else if (letterName === "witness-statement-letter") {
      letterText = finalLetter;
      templateId = "d-2b89388849d5456483e44d36b35b02b6";
      fileName = "monaco_ai_witness_statement";
    } else {
      letterText = finalLetter;
      templateId = "d-a3433582fd424844814ed90c4c5b4d2c";
      fileName = "advice-note";
    }
    if (loginType === "lawyer") {
      setShowModal(!showModal);
    } else {
      try {
        await sendMail(
          userData?.email,
          userData?.username,
          userData?.email,
          letterText,
          fileName,
          templateId,
          paidConUrl
        );
      } catch (error) {
        console.error(error);
      } finally {
        setShowEmailModal(true);
      }
    }
  };

  const saveLetter = async () => {
    let updateField = {};
    const timeStamp = new Date().getTime();
    switch (letterName) {
      case "wp-letter":
        updateField = {
          isWpLetterGenerated: true,
          isWpLetterChecked: true,
          checkedWpLetter: finalLetter,
          wpLetterSetOn: timeStamp,
        };
        break;
      case "grievance-letter":
        updateField = {
          isGrievanceLetterGenerated: true,
          isGrievanceLetterChecked: true,
          checkedGrievanceLetter: finalLetter,
          grievanceLetterSetOn: timeStamp,
        };
        break;
      case "et1-letter":
        updateField = {
          isEt1LetterGenerated: true,
          isEt1LetterChecked: true,
          checkedEt1Letter: finalLetter,
          et1LetterSetOn: timeStamp,
        };
        break;
      case "witness-statement-letter":
        updateField = {
          isWitnessStatementLetterGenerated: true,
          isWitnessStatementLetterChecked: true,
          checkedWitnessStatementLetter: finalLetter,
          witnessStatementLetterSetOn: timeStamp,
        };
        break;
      case "dsar-letter":
        updateField = {
          isDsarLetterGenerated: true,
          isDsarLetterChecked: true,
          checkedDsarLetter: finalLetter,
          dsarLetterSetOn: timeStamp,
        };
        break;
      default:
        console.log("Letter type does not match any update criteria.");
        return;
    }
    try {
      await updateUser(authToken, updateField);
      navigate("/dashboard");
    } catch (e) {
      console.error(
        "An error occurred while updating the user and letter: ",
        e
      );
      setError("An error occurred while updating.");
    }
  };

  if (!state) {
    return (
      <div className="letter">
        <div className="letter-header">
          <p>No letter data available.</p>
        </div>
      </div>
    );
  }
  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="letter">
      <div className="letter-header">
        {letterTitle && <h5>{letterTitle}</h5>}
        <div className="letter-status">
          <img src={CheckIcon} alt="check-icon" />
          <p>Letter ready</p>
        </div>
      </div>
      <div className="letter-container">
        <div className="letter-body">
          <div className="letter-content-container">
            <h5>{letterTitle}</h5>
            <div className="letter-content">
              <ReactMarkdown>{finalLetter}</ReactMarkdown>
            </div>
            {isStreamComplete && (
              <div className="letter-actions">
                <div className="send-email" onClick={sendToMail}>
                  <img src={EmailIcon} alt="email-icon" /> Send to email
                </div>
              </div>
            )}
          </div>
          <div className="letter-sidebar">
            <h5>Learn more</h5>
            <YoutubeEmbed embedId={embedId} width="100%" height="185" />
            {isStreamComplete && (
              <div className="save-button" onClick={saveLetter}>
                Save & continue
              </div>
            )}
          </div>
          {showEmailModal && (
            <ThankYouModal
              handleModal={handleEmailModal}
              visible={showEmailModal}
            />
          )}
          {showModal && (
            <EmailModal
              handleEmailModal={sendToMail}
              visible={showModal}
              letterName={letterName}
              letterData={finalLetter}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CheckLetter;
