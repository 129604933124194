import React from "react";

function Thankyou() {
  return (
    <>
      <div className="thank-you">
        <p>Thanks for your info!</p>
        <h2>We will be in touch via email in the next few minutes.</h2>
      </div>
    </>
  );
}

export default Thankyou;
