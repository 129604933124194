import Axios from "../api/axios";
import config from "../../config";

const grappleAi = (message, system, email, requestTag) => {
  return Axios.post(`${config.BASE_URL}grapple-ai`, {
    message: message,
    system: system,
    email: email,
    requestTag: requestTag,
  });
};

export default grappleAi;
